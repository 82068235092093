import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import BuyIcon from "../../images/icons/BuyIcon";
import Skeleton from "../../components/Skeleton/Skeleton";
import "./VideoItem.scss";

function VideoItem({ video, index }) {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const videoRef = useRef(null);
  const containerRef = useRef(null);

  // Навигация на страницу видео
  const handleClickOnVideo = useCallback(
    (e) => {
      e.preventDefault();
      if (!video.fullName || !video.code) return;
      navigate(
        `/devices/${encodeURIComponent(video.code)}/${encodeURIComponent(
          video.fullName
        )}`
      );
    },
    [navigate, video.fullName, video.code]
  );

  // Ховер-эффекты для видео (НЕ меняем)
  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
    if (videoRef.current) {
      videoRef.current
        .play()
        .catch((err) => console.warn("Ошибка воспроизведения:", err.message));
    }
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }, []);

  // Отслеживание появления видео в зоне видимости
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.2 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  // Ждем полной загрузки видео перед его отображением
  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div
      ref={containerRef}
      name={video.fullName}
      onClick={handleClickOnVideo}
      className={`video-thumb ${video.orientation} button`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Видео загружается только когда в зоне видимости */}
      {isVisible && (
        <video
          ref={videoRef}
          name={video.fullName}
          loop
          muted
          preload="metadata"
          className="list-video"
          id={`video-${index}`}
          onLoadedData={handleVideoLoad}
          style={{
            opacity: isHovered && isVideoLoaded ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
          }}
        >
          <source src={video.url} type="video/mp4" />
        </video>
      )}

      {/* Скелетон - показывается пока не загрузится изображение */}
      {!isImageLoaded && (
        <Skeleton
          classNames="video-skeleton"
          variant="rect"
          width="100%"
          height="100%"
        />
      )}

      <img
        src={video.thumbnail}
        alt="Preview"
        loading={index === 0 ? "eager" : "lazy"}
        fetchpriority={index === 0 ? "high" : "auto"}
        style={{
          opacity: isHovered && isVideoLoaded ? 0 : 1,
          transition: "opacity 0.3s ease-in-out",
        }}
        onLoad={() => setIsImageLoaded(true)}
      />

      <div className="button-buy">
        <BuyIcon color="#000000" />
      </div>
    </div>
  );
}

export default VideoItem;
