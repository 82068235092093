import { useEffect, useRef, useMemo, useCallback } from "react";
import Loader from "../../images/loading.gif";
import "./VideosSection.scss";
import { useSelector } from "react-redux";
import VideoItem from "./VideoItem";

const calculateVideoHeight = (video) =>
  video.orientation === "vertical" ? 4 : 1;

const distributeVideosToColumns = (videos, numColumns) => {
  const columns = Array.from({ length: numColumns }, () => []);
  const columnHeights = Array(numColumns).fill(0);

  videos.forEach((video) => {
    const videoHeight = calculateVideoHeight(video);
    const targetColumnIndex = columnHeights.indexOf(Math.min(...columnHeights));
    columns[targetColumnIndex].push(video);
    columnHeights[targetColumnIndex] += videoHeight;
  });

  return columns;
};

const VideosSection = ({ isLoading, afterScroll }) => {
  const currentVideos = useSelector((state) => state.currentVideos);
  const elementToScroll = useRef(null);

  // Запоминаем количество колонок
  const numColumns = 4;

  // Оптимизируем распределение видео по колонкам
  const columns = useMemo(
    () => distributeVideosToColumns(currentVideos, numColumns),
    [currentVideos, numColumns]
  );

  // Оптимизируем обработчик скролла
  const handleScroll = useCallback(afterScroll, [afterScroll]);

  useEffect(() => {
    const scrollable = elementToScroll.current;
    if (scrollable) {
      scrollable.addEventListener("wheel", handleScroll);
    }
    return () => {
      scrollable?.removeEventListener("wheel", handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <div className="video-container" ref={elementToScroll}>
        {columns.map((column, columnIndex) => (
          <div className="column-container" key={columnIndex}>
            {column.map((vid, index) => (
              <VideoItem video={vid} index={index} key={vid.fullName + index} />
            ))}
          </div>
        ))}
      </div>

      {isLoading && (
        <span className="loading">
          <img alt="videos loading" src={Loader} />
        </span>
      )}
    </>
  );
};

export default VideosSection;
