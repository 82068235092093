import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import VideosSection from "./VideosSection";
import { fetchVideos } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentVideos } from "../../store/actions";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  GALAXYA15,
  GALAXYA15TITLE,
  IPHONE16,
  IPHONE16TITLE,
  IPAD,
  IPADTITLE,
  PIXEL8,
  PIXEL8TITLE,
} from "../../helpers/enums";

export default function Devices() {
  const dispatch = useDispatch();
  const currentVideos = useSelector((state) => state.currentVideos);

  const { device } = useParams();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);

  const determineDeviceTitle = () => {
    const deviceTitles = {
      [GALAXYA15]: GALAXYA15TITLE,
      [IPHONE16]: IPHONE16TITLE,
      [PIXEL8]: PIXEL8TITLE,
      [IPAD]: IPADTITLE,
    };

    return deviceTitles[device] || "";
  };

  const fetchData = async (loc = location, videos = [], page = 1) => {
    setIsLoading(true);
    const { currentVids, nextPage } = await fetchVideos(
      device || "all",
      videos,
      page
    );
    dispatch(setCurrentVideos(currentVids));
    setPrevPage(page);
    setPage(nextPage);
    setIsLoading(false);
  };

  useEffect(() => {
    dispatch(setCurrentVideos([]));
    async function fetchData(loc = location, videos = [], page = 1) {
      setIsLoading(true);
      const { currentVids, nextPage } = await fetchVideos(
        device || "all",
        videos,
        page
      );

      dispatch(setCurrentVideos(currentVids));
      setPrevPage(page);
      setPage(nextPage);
      setIsLoading(false);
    }

    fetchData(location);
  }, [device, dispatch, location]);

  const afterScroll = () => {
    const scrolledTo = window.scrollY + window.innerHeight;
    const isReachBottom = document.body.scrollHeight - 10 <= scrolledTo;
    if (isReachBottom && prevPage !== page) {
      fetchData(location, currentVideos, page);
    }
  };

  return (
    <div className="videos-section page-wrapper">
      <Helmet>
        <title>
          UnrealHands - Professional Green Screen Video Mock-ups and Custom
          Content Integration
        </title>
        <meta
          name="description"
          content="Discover high-quality green screen video mock-ups for iPhones, Samsung Galaxy, Google Pixel, and more. Seamlessly integrate your content with professional video replacement services, including screen light matching, gesture tracking, and custom backgrounds. Perfect for app promotions, social media campaigns, and brand showcases. Fast delivery and tailored solutions available."
        />
      </Helmet>

      {!!currentVideos.length && (
        <span className="videos-title">
          {determineDeviceTitle()} Green Screen video mock-ups
        </span>
      )}

      <VideosSection isLoading={isLoading} afterScroll={afterScroll} />
    </div>
  );
}
