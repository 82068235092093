import { ref, getDownloadURL } from "firebase/storage";
import {
  ref as dbRef,
  startAfter,
  limitToFirst,
  get,
  query,
  update,
  orderByKey,
} from "firebase/database";
import { storage, database } from "./firebase";
import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";
import { GALAXYA15, IPAD, IPHONE16, PER_PAGE } from "./enums.js";

export const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const sendEmail = async (
  email_to,
  to_name,
  message,
  from_name,
  from_email,
  template = "template_jjalv7h"
) => {
  try {
    const templateParams =
      template === "template_jjalv7h"
        ? {
            to_name,
            email_to,
            message,
          }
        : {
            email_to,
            message,
            from_name,
            from_email,
          };
    await emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      template,
      templateParams,
      {
        publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      }
    );
    return true;
  } catch (e) {
    if (e instanceof EmailJSResponseStatus) {
      console.log("EMAILJS FAILED...", e);
    }
    return false;
  }
};

export const capitalizeFirstLetter = (string) => {
  return string ? string[0].toUpperCase() + string.slice(1) : "";
};

export const getBoughtDownloadURL = async (
  device,
  resolution,
  orientation = "horizontal",
  itemName
) => {
  try {
    return await getDownloadURL(
      ref(
        storage,
        `${device}/${resolution.toLowerCase()}/${orientation}/${
          itemName.split(".")[0]
        }.mov`
      )
    );
  } catch (e) {
    console.error("Error fetching download URL:", e.message);
    return null;
  }
};

export const snapshotToArray = (snapshot) => {
  if (!snapshot || !snapshot.exists()) return [];

  const arr = [];
  snapshot.forEach((child) => {
    arr.push(child);
  });

  return arr;
};

export const formatAndUpdate = async (snapArr, device, currentVids = []) => {
  const newVids = [...currentVids];

  for (const child of snapArr) {
    const { key } = child;

    const title = child.child("title").val();
    const keywords = child.child("keywords").val();
    const usage = child.child("usage").val();
    const framerate = child.child("framerate").val() || "24 FPS";
    const codec = child.child("codec").val() || "MOV ProRes 422";
    let thumbnail = child.child("thumbnail").val();
    let video = child.child("video").val();

    const orientation = key.includes("H_CAM")
      ? "horizontal"
      : key.includes("V_CAM")
      ? "vertical"
      : null;

    const vidFormat =
      ["galaxya15", "ipad"].includes(device) ||
      (device === "iphone16" && orientation === "vertical")
        ? ".mp4"
        : ".mov";

    // If there are no videos or previews, download them in parallel
    const fetchVideo = !video
      ? getDownloadURL(
          ref(storage, `${device}/sd/${orientation}/${key}${vidFormat}`)
        )
      : Promise.resolve(video);

    const fetchThumbnail = !thumbnail
      ? getDownloadURL(
          ref(storage, `${device}/preview/${orientation}/${key}.jpg`)
        )
      : Promise.resolve(thumbnail);

    [video, thumbnail] = await Promise.all([fetchVideo, fetchThumbnail]);

    // Update the database if a video or preview has been downloaded
    const updates = {};
    if (!child.child("video").val()) updates.video = video;
    if (!child.child("thumbnail").val()) updates.thumbnail = thumbnail;
    if (Object.keys(updates).length > 0) {
      await update(dbRef(database, `videos/${device}/${key}`), updates);
    }

    if (!newVids.some((vid) => vid.fullName === key)) {
      newVids.push({
        keywords: keywords?.split(",") || [],
        code: device,
        orientation,
        fullName: key,
        title,
        framerate,
        codec,
        resolution: [
          { id: "720p", type: "HD (1920x1080px)", price: 39 },
          { id: "2160p", type: "4K (3840x2160px)", price: 99 },
        ],
        usage,
        url: video,
        thumbnail,
      });
    }
  }

  return newVids;
};

export const fetchVideos = async (device, currentVids = [], page = 1) => {
  return new Promise(async (res, rej) => {
    if (device === "all") {
      try {
        let newCurrentVids = [...currentVids];

        const devices = [IPHONE16, GALAXYA15, IPAD]; // Список устройств
        for (const dev of devices) {
          let startingPoint = newCurrentVids.length
            ? newCurrentVids[newCurrentVids.length - 1]
            : null;

          const snap = startingPoint
            ? await get(
                query(
                  dbRef(database, `videos/${dev}`),
                  orderByKey(),
                  startAfter(startingPoint?.fullName),
                  limitToFirst(PER_PAGE) // Загружаем полное PER_PAGE для каждого устройства
                )
              )
            : await get(
                query(
                  dbRef(database, `videos/${dev}`),
                  orderByKey(),
                  limitToFirst(PER_PAGE)
                )
              );

          const snapArr = await snapshotToArray(snap);
          newCurrentVids = await formatAndUpdate(snapArr, dev, newCurrentVids);
        }

        // Удаление дубликатов
        newCurrentVids = newCurrentVids.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.fullName === item.fullName)
        );

        const isLastPage = newCurrentVids.length === currentVids.length;
        res({
          currentVids: newCurrentVids,
          nextPage: isLastPage ? page : page + 1,
        });
      } catch (e) {
        console.log("Firebase error: ", e.message);
        rej({ currentVids, nextPage: page });
      }
    } else {
      try {
        let newCurrentVids = [...currentVids];
        const startingPoint = newCurrentVids.length
          ? newCurrentVids[newCurrentVids.length - 1]
          : null;

        const deviceQ = startingPoint
          ? await get(
              query(
                dbRef(database, `videos/${device}`),
                orderByKey(),
                startAfter(startingPoint?.fullName),
                limitToFirst(PER_PAGE)
              )
            )
          : await get(
              query(
                dbRef(database, `videos/${device}`),
                orderByKey(),
                limitToFirst(PER_PAGE)
              )
            );

        const deviceQArr = await snapshotToArray(deviceQ);
        newCurrentVids = await formatAndUpdate(
          deviceQArr,
          device,
          newCurrentVids
        );

        // Удаление дубликатов
        newCurrentVids = newCurrentVids.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.fullName === item.fullName)
        );

        res({
          currentVids: newCurrentVids,
          nextPage:
            newCurrentVids.length === currentVids.length ? page : page + 1,
        });
      } catch (e) {
        console.log("Firebase error: ", e.message);
        rej({ currentVids, nextPage: page });
      }
    }
  });
};

export const getVideoData = async (device, title) => {
  try {
    const snapshot = await get(dbRef(database, `videos/${device}/${title}`));

    if (!snapshot.exists()) {
      console.warn("No data available");
      return null;
    }
    return formatAndUpdate([snapshot], device, []);
  } catch (error) {
    console.error("Error getting video data:", error);
    return null;
  }
};
